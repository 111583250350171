import { Avatar, Box, Grid2, Paper, Tooltip, Typography } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";

import { useNavigate } from "react-router-dom";

import "./dashboard.css";
import { useAppDispatch, useAppSelector } from "../../store/adminator-store";
import { CompanyDto } from "../../store/slicers/user-settings.slicer";
import { useEffect } from "react";
import { setBreadcrumbs } from "../../store/slicers/breadcrumbs.slicer";

import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import DescriptionIcon from "@mui/icons-material/Description";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import SettingsIcon from "@mui/icons-material/Settings";
import InventoryIcon from "@mui/icons-material/Inventory";

const iconSize = "5em";

const tiles = [
  {
    title: "Offertes",
    link: "/quotes",
    icon: <RequestQuoteIcon style={{ fontSize: iconSize }} color="primary" />,
    // icon: <img src="./icons/project-management.png" width="120" />,
    description: "Volg offertes op en communiceer met uw klanten",
    disabled: false,
  },
  {
    title: "Werkplanning",
    link: "/invoices",
    icon: <PunchClockIcon style={{ fontSize: iconSize }} color="primary" />,
    // icon: <img src="./icons/schedule.png" width="120" />,
    description: "Beheer uw geplande afspraken",
    disabled: true,
  },
  {
    title: "Facturen",
    link: "/invoices",
    icon: <DescriptionIcon style={{ fontSize: iconSize }} color="primary" />,
    // icon: <img src="./icons/invoice.png" width="120" />,
    description: "Raadpleeg uw facturen",
    disabled: true,
  },
  {
    title: "Artikels",
    link: "/articles",
    icon: <InventoryIcon style={{ fontSize: iconSize }} color="primary" />,
    // icon: <img src="./icons/packages.png" width="120" />,
    description: "Beheer de artikelen van uw bedrijf",
    disabled: false,
  },
  {
    title: "Beheer",
    link: "/settings",
    icon: <SettingsIcon style={{ fontSize: iconSize }} color="primary" />,
    // icon: <img src="./icons/settings.png" width="120" />,
    description: "Pas instellingen aan",
    disabled: false,
  },
];

export const DashboardPage = () => {
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();

  const userSettings = useAppSelector((state) => state.userSettings);

  useEffect(() => {
    appDispatch(setBreadcrumbs([]));
  }, []);

  return (
    <>
      <Grid2 container justifyContent="center" direction="column" spacing={8}>
        <Grid2 className="blurred entity-details-header">
            <Box p={1} pb={2}>
              <Grid2
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid2>
                <Avatar
                  alt="fallback"
                  sx={{width: 60, height: 60}}
                  imgProps={{ referrerPolicy: "no-referrer" }}
                ></Avatar>
                </Grid2>
                <Grid2>
                  <Typography variant="h5" color="primary" style={{wordBreak: "break-all"}}>
                    {
                      userSettings.companies.find(
                        (x: CompanyDto) =>
                          x.id == userSettings.selectedCompanyId
                      )?.name
                    }
                  </Typography>
                </Grid2>
              </Grid2>
            </Box>
        </Grid2>
        <Grid2
          container
          justifyContent="center"
          alignContent="space-around"
          alignItems="center"
          spacing={5}
        >
          {tiles.map((tile, index) => (
            <Grid2
              width={250}
              height={250}
              key={index}
              className="dashboard-tile"
            >
              <Tooltip title={tile.disabled ? "Geduld!!!" : ""}>
                <Paper
                  elevation={5}
                  style={{
                    cursor: "pointer",
                    height: "inherit",
                    borderRadius: "10%",
                    // pointerEvents: tile.disabled ? "none" : "initial",
                    opacity: tile.disabled ? "0.5" : "1",
                  }}
                  onClick={() => navigate(tile.link)}
                >
                  <Grid2
                    container
                    direction="column"
                    p={2}
                    pb={5}
                    justifyContent="space-between"
                    style={{ height: "inherit" }}
                  >
                    <Grid2 mt="-20%">{tile.icon}</Grid2>
                    <Grid2>
                      <Typography variant="h5" color="primary">
                        <b>{tile.title}</b>
                      </Typography>
                    </Grid2>
                    <Grid2>
                      <Typography variant="caption">
                        {tile.description}
                      </Typography>
                    </Grid2>
                  </Grid2>
                </Paper>
              </Tooltip>
            </Grid2>
          ))}
        </Grid2>
      </Grid2>
    </>
  );
};
