import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  Divider,
  Fade,
  FormControlLabel,
  Grid2,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Slide,
  SpeedDial,
  SpeedDialAction,
  Switch,
  TextField,
  Theme,
  ToggleButton,
  Typography,
  Zoom,
} from "@mui/material";
import { Grid } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { NumericFormatCustom } from "../../components/shared/NumericFormatCustom";
import { useAppDispatch, useAppSelector } from "../../store/adminator-store";
import { setBreadcrumbs } from "../../store/slicers/breadcrumbs.slicer";
import { articlesCrumbs } from "../breadcrumbs";
import {
  getMediaUrl,
  httpClient,
} from "../../infrastructure/http-client/http-client";
import {
  ArticleDto,
  createArticle,
  fetchArticles,
} from "../../store/slicers/articles.slicer";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const ArticlesPage = () => {
  const appDispatch = useAppDispatch();
  const [vatIncl, setVatIncl] = useState(false);

  const articles: ArticleDto[] = useAppSelector(
    (state) => state.articleState.articles
  );
  const isLoading: boolean = useAppSelector(
    (state) => state.articleState.isLoading
  );
  const isSaving: boolean = useAppSelector(
    (state) => state.articleState.isSaving
  );
  const selectedCompanyId: number = useAppSelector(
    (state) => state.userSettings.selectedCompanyId
  );

  const [editingArticle, setEditingArticle] = useState<ArticleDto | undefined>(
    undefined
  );
  const [nameSearch, setNameSearch] = useState(undefined);
  const [uploadedImage, setUploadedImage] = useState<FileList | undefined>(
    undefined
  );

  const [iconBlob, setIconBlob] = useState<string | undefined>(undefined);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const iconUploadRef = useRef<HTMLInputElement>();

  const filteredArticles = articles
    .filter(
      (x) =>
        !nameSearch ||
        x.name.toLowerCase().indexOf(nameSearch.toLowerCase()) >= 0
    )
    .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

  const closeEditArticle = () => {
    setEditingArticle(undefined);
    setUploadedImage(undefined);
  };

  const showCreateArticle = () => {
    setEditingArticle({
      id: -1,
      name: "",
      buyPrice: 0,
      sellPrice: 0,
      vat: 0,
      stockItemType: 0,
      iconUrl: undefined,
    });
  };

  const editArticle = (article) => {
    setEditingArticle(article);
  };

  const createOrUpdateEditingArticle = async () => {
    let iconUrl = undefined;
    let payload: ArticleDto = { ...editingArticle };

    if (uploadedImage?.length ?? 0 >= 1) {
      iconUrl = await uploadFile(payload.id, uploadedImage);
      payload.iconUrl = iconUrl;
    }

    await propertyChanged("iconUrl", iconUrl);
    await appDispatch(
      createArticle({
        companyId: selectedCompanyId,
        article: payload,
      })
    );
    closeEditArticle();
  };

  const openFileSelect = () => {
    iconUploadRef.current.click();
  };

  const uploadFile = async (
    articleId: number,
    fileList: FileList
  ): Promise<string> => {
    const file = fileList.item(0);

    const formData = new FormData();
    formData.append("file", file);

    return await httpClient.postForm<string>(
      `/media/article/${articleId}`,
      formData
    );
  };

  const propertyChanged = async (name: string, value: any) => {
    const clone = { ...editingArticle };
    clone[name] = value;
    await setEditingArticle(clone);
  };

  useEffect(() => {
    appDispatch(setBreadcrumbs(articlesCrumbs()));
  }, []);

  useEffect(() => {
    setIconBlob(
      uploadedImage ? URL.createObjectURL(uploadedImage.item(0)) : undefined
    );
  }, [uploadedImage]);

  return (
    <Grid2 size={12} container spacing={1}>
      <Grid size={12} className="entity-details-header">
        <Paper className="blurred">
          <Grid2 size={12} container alignItems="center" p={1} spacing={1}>
            <Grid2 size="grow">
              <TextField
                fullWidth
                label="Zoek"
                value={nameSearch}
                size="small"
                onChange={(e) => setNameSearch(e.target.value)}
              />
            </Grid2>
            <Grid2>
              <FormControlLabel
                checked={vatIncl}
                onChange={(e) => setVatIncl(e.target.checked)}
                label="Toon Incl. BTW"
                control={<Switch />}
              />
            </Grid2>
            <Grid2 size={12} container>
              <Grid2>
                <DatePicker
                  label="Van"
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </Grid2>
              <Grid2>
                <DatePicker
                  label="Tot"
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </Grid2>
              <Grid2>
                <FormControlLabel label="Door mij aangemaakt" control={<Checkbox />} />
              </Grid2>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid>
      <Grid2 size={12}>{isLoading && <CircularProgress />}</Grid2>
      {!isLoading && articles.length == 0 && (
        <Grid2 size={12} container direction="column">
          <Grid2>
            <Typography>Geen resultaten</Typography>
          </Grid2>
          <Grid2>
            <Button
              variant="contained"
              color="success"
              onClick={() => showCreateArticle()}
            >
              Maak uw eerste artikel
            </Button>
          </Grid2>
        </Grid2>
      )}
      <Grid2 container direction="row" size={12} spacing={3}>
        {filteredArticles.map((article) => {
          const vat = vatIncl ? article.vat / 100 + 1 : 1;
          return (
            <Grid2 key={article.id} size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
              <Grow in>
                <Card elevation={3}>
                  <CardMedia
                    image={getMediaUrl("article", article.iconUrl)}
                    sx={{ height: "10em" }}
                  />
                  <CardContent>
                    <Grid2 container spacing={1}>
                      {/* <Grid2
                        size={12}
                        height={120}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <img
                          height={120}
                          src={getMediaUrl("article", article.iconUrl)}
                        />
                      </Grid2> */}
                      <Grid2 size={12} mb={1}>
                        <Typography fontWeight="bold">
                          {article.name}
                        </Typography>
                      </Grid2>
                      <Grid2 size={12}>
                        <Divider />
                      </Grid2>
                      <Grid2 size={6}>
                        <Typography textAlign="left">Aankoop prijs</Typography>
                      </Grid2>
                      <Grid2 size={6} container justifyContent="flex-end">
                        <b>
                          <NumericFormat
                            value={(article.buyPrice * vat).toFixed(2)}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="€ "
                          />
                        </b>
                      </Grid2>
                      <Grid2 size={12}>
                        <Divider />
                      </Grid2>
                      <Grid2 size={6}>
                        <Typography textAlign="left">Verkoop prijs</Typography>
                      </Grid2>
                      <Grid2 size={6} container justifyContent="flex-end">
                        <b>
                          <NumericFormat
                            value={(article.sellPrice * vat).toFixed(2)}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="€ "
                          />
                        </b>
                      </Grid2>
                      <Grid2 size={12}>
                        <Divider />
                      </Grid2>
                      <Grid2 size={6}>
                        <Typography textAlign="left">BTW tarief</Typography>
                      </Grid2>
                      <Grid2 size={6} container justifyContent="flex-end">
                        <b>
                          <NumericFormat
                            value={article.vat}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="% "
                          />
                        </b>
                      </Grid2>
                      <Grid2 size={12}>
                        <Divider />
                      </Grid2>
                      <Grid2
                        size={12}
                        container
                        justifyContent="space-between"
                        direction="row"
                      >
                        <Grid2>
                          <Typography textAlign="left">Omzet</Typography>
                        </Grid2>
                        <Grid2 size="grow" container justifyContent="flex-end">
                          <Box pr={1}>
                            <Typography color="success">
                              {article.buyPrice > 0
                                ? `(${(
                                    ((article.sellPrice - article.buyPrice) /
                                      article.buyPrice) *
                                    100
                                  ).toFixed(2)}%)`
                                : "(N/A)"}
                            </Typography>
                          </Box>
                          <NumericFormat
                            value={(
                              article.sellPrice - article.buyPrice
                            ).toFixed(2)}
                            renderText={(props) => (
                              <Typography fontWeight="bold" color="success">
                                {props}
                              </Typography>
                            )}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="€ "
                          />
                        </Grid2>
                      </Grid2>
                      <Grid2 size={12}>
                        <Divider />
                      </Grid2>
                      <Grid2
                        size={12}
                        container
                        justifyContent="space-between"
                        direction="row"
                        alignItems="center"
                      >
                        <Grid2>
                          <Typography textAlign="left">In stock</Typography>
                        </Grid2>
                        <Grid2 size={6} container justifyContent="flex-end">
                          <TextField
                            value={editingArticle?.buyPrice}
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            // onChange={(e) =>
                            //   propertyChanged(
                            //     "buyPrice",
                            //     parseFloat(e.target.value)
                            //   )
                            // }
                            sx={{
                              [`& input`]: {
                                textAlign: "right",
                              },
                            }}
                            onClick={(e) => e.target.select()}
                            slotProps={{
                              input: {
                                inputComponent: NumericFormatCustom as any,
                                slotProps: {
                                  input: {},
                                },
                                style: {
                                  resize: "both",
                                },
                              },
                            }}
                          />
                        </Grid2>
                      </Grid2>
                      <Grid2 size={12}>
                        <Divider />
                      </Grid2>
                      <Grid2 size={12} container justifyContent="space-between">
                        <Grid2 size="grow">
                          <Button
                            fullWidth
                            startIcon={<EditIcon />}
                            variant="contained"
                            onClick={() => editArticle(article)}
                          >
                            Bewerk
                          </Button>
                        </Grid2>
                        <Grid2>
                          {/* <Button startIcon={<DeleteIcon/>} variant="contained">Verwijder</Button> */}
                          <IconButton color="error">
                            <DeleteIcon />
                          </IconButton>
                        </Grid2>
                      </Grid2>
                      {/* <Grid2 size={12}>
                        <Button
                          fullWidth
                          startIcon={<EditIcon />}
                          variant="text"
                          size="small"
                          onClick={() => editArticle(article)}
                        >
                          Voorraad beheren
                        </Button>
                      </Grid2> */}
                    </Grid2>
                  </CardContent>
                </Card>
              </Grow>
            </Grid2>
          );
        })}
      </Grid2>
      <Grid2
        size={12}
        container
        justifyContent="center"
        className="entity-details-header"
        style={{ top: 0, bottom: 20 }}
      >
        <Paper className="blurred ">
          <Box p={1}>
            <Pagination
              page={page}
              onChange={(_, val) => setPage(val)}
              size="large"
              count={Math.ceil(articles.length / pageSize)}
            />
          </Box>
        </Paper>
      </Grid2>
      <Dialog
        open={editingArticle != undefined}
        // onClose={() => closeEditArticle()}
        maxWidth={false}
      >
        <Box p={2}>
          <Grid2
            container
            direction="column"
            spacing={1}
            justifyContent="center"
          >
            <Grid2>
              <Typography textAlign="center" variant="h4">
                {editingArticle && editingArticle.id >= 0
                  ? "Artikel bewerken"
                  : "Nieuw Artikel"}
              </Typography>
            </Grid2>
            <Grid2
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid2>
                <img
                  src={
                    iconBlob ?? getMediaUrl("article", editingArticle?.iconUrl) // getMediaUrl("article", editingArticle?.iconUrl)
                  }
                  height={200}
                  referrerPolicy="no-referrer"
                />
              </Grid2>
              <Grid2>
                <Button
                  onClick={() => openFileSelect()}
                  startIcon={<FileUploadIcon />}
                >
                  Foto uploaden
                </Button>
                <input
                  id="myInput"
                  type="file"
                  ref={iconUploadRef}
                  style={{ display: "none" }}
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => setUploadedImage(e.target.files)}
                />
                {/* <Button startIcon={<ContentCopyIcon  />}>Kopieren van clipboard</Button> */}
              </Grid2>
            </Grid2>
            <Grid2>
              <TextField
                size="small"
                fullWidth
                label="Naam"
                value={editingArticle?.name}
                onChange={(e) => propertyChanged("name", e.target.value)}
              />
            </Grid2>
            <Grid2
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid2>
                <Typography variant="subtitle2">
                  Aankoopprijs (Excl. BTW)
                </Typography>
              </Grid2>
              <Grid2 size={6}>
                <TextField
                  value={editingArticle?.buyPrice}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    propertyChanged("buyPrice", parseFloat(e.target.value))
                  }
                  sx={{
                    [`& input`]: {
                      textAlign: "right",
                    },
                  }}
                  onClick={(e) => e.target.select()}
                  slotProps={{
                    input: {
                      inputComponent: NumericFormatCustom as any,
                      slotProps: {
                        input: {
                          prefix: "€ ",
                        },
                      },
                      style: {
                        resize: "both",
                      },
                    },
                  }}
                />
              </Grid2>
            </Grid2>
            <Grid2
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid2>
                <Typography variant="subtitle2">
                  Verkoopprijs (Excl. BTW)
                </Typography>
              </Grid2>
              <Grid2 size={6}>
                <TextField
                  value={editingArticle?.sellPrice}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    propertyChanged("sellPrice", parseFloat(e.target.value))
                  }
                  sx={{
                    [`& input`]: {
                      textAlign: "right",
                    },
                  }}
                  onClick={(e) => e.target.select()}
                  slotProps={{
                    input: {
                      inputComponent: NumericFormatCustom as any,
                      slotProps: {
                        input: {
                          prefix: "€ ",
                        },
                      },
                      style: {
                        resize: "both",
                      },
                    },
                  }}
                />
              </Grid2>
            </Grid2>
            <Grid2
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid2>
                <Typography variant="subtitle2">BTW tarief</Typography>
              </Grid2>
              <Grid2 size={6}>
                <TextField
                  value={editingArticle?.vat}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    propertyChanged("vat", parseFloat(e.target.value))
                  }
                  onClick={(e) => e.target.select()}
                  sx={{
                    [`& input`]: {
                      textAlign: "right",
                    },
                  }}
                  slotProps={{
                    input: {
                      inputComponent: NumericFormatCustom as any,
                      slotProps: {
                        input: {
                          prefix: "% ",
                        },
                      },
                      style: {
                        resize: "both",
                      },
                    },
                  }}
                />
              </Grid2>
            </Grid2>{" "}
            <Grid2
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid2>
                <Typography variant="subtitle2">Eenheid</Typography>
              </Grid2>
              <Grid2 size={6}>
                <Select fullWidth value={0} size="small">
                  <MenuItem value={0}>Stuk</MenuItem>
                  <MenuItem value={1}>Prestatie / Uur</MenuItem>
                </Select>
              </Grid2>
            </Grid2>
            <Grid2 container justifyContent="space-between">
              <Grid2>
                <Button
                  startIcon={<ArrowBackIcon />}
                  variant="text"
                  onClick={() => closeEditArticle()}
                >
                  Annuleren
                </Button>
              </Grid2>
              <Grid2>
                <Button
                  onClick={() => createOrUpdateEditingArticle()}
                  startIcon={
                    isSaving ? <CircularProgress size={20} /> : <SaveIcon />
                  }
                  disabled={isSaving}
                  variant="contained"
                  color="success"
                >
                  Opslaan
                </Button>
              </Grid2>
            </Grid2>
          </Grid2>
        </Box>
      </Dialog>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClick={() => showCreateArticle()}
      ></SpeedDial>
    </Grid2>
  );
};
